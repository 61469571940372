<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/message/screens' }"
      >首页
      </el-breadcrumb-item
      >
      <el-breadcrumb-item>影厅列表</el-breadcrumb-item>
      <el-breadcrumb-item>售出详情</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 底部卡片 -->
    <div>

<!--      <el-card>-->
<!--        <div style="font-weight: bold; font-size: 20px;">-->
<!--          全部座位售出情况-->
<!--          <span style="font-size: 16px; margin-left: 10px">-->
<!--              已出售：{{ showData.已售出座位数 }} / 未出售：-->
<!--              {{ showData.可用座位数 - showData.已售出座位数 }} / 不可用：{{-->
<!--              showData.不可用座位数-->
<!--            }}-->
<!--            </span>-->
<!--        </div>-->
<!--      </el-card>-->
      <el-row :gutter="24" style="margin-top:10px" :offset="5">
        <el-col :span="8">
          <el-card :body-style="{'padding':'10px 10px','user-select': 'none'}">
            <div>
              <p>演出日期：</p>
              <el-date-picker
                  type="date"
                  :clearable="false"
                  placeholder="请选择日期"
                  v-model="selectedDate"
                  @change="getSessionList()"
                  style="width:100%"
              ></el-date-picker>
            </div>
            <div>
              <el-select
                  v-model="selectedValue"
                  placeholder="请选择场次"
                  @change="handleClick"
                  style="width:100%"
              >
                <el-option
                    v-for="(item, index) in sessionList"
                    :key="index"
                    :label="item.放映影片.名称 + '：' + item.开始时间"
                    :value="item.ID"
                >
                </el-option>
              </el-select>
            </div>
            <div class="ticket-item" style="margin-top: 15px">
              <div v-if="ticket.length === 0">暂无可售票种信息</div>
              <div
                  :class="{ activeTicket: item.ID === activeTicketData.ID }"
                  @click="selectTicketType(item)"
                  v-for="(item, index) in ticket"
                  :key="index"
              >
                <div style="display:flex;justify-content:space-between">
                  <div>价格：<span style="font-weight: bold">{{ item.price }}</span></div>
                  <div><i class="el-icon-user-solid"></i>x{{ item.seat_number }}</div>
                </div>
                <div>票名：{{ item.name }}</div>
                <div>区域：{{ item.areaName }}</div>
              </div>
            </div>

          </el-card>

        </el-col>
        <el-col :span="8" v-if="ticket.length > 0">
          <el-card :body-style="{'padding':'10px 10px'}">
            <div>
              <span>客户姓名：</span>
              <el-input v-model="person_name"></el-input>
              <el-divider class="myel-divider"></el-divider>
              <span>手机号码：</span>
              <el-input v-model="person_tel"></el-input>
              <el-divider class="myel-divider"></el-divider>
              <span>购买数量：</span>
              <el-input v-model="activeTicketNum"></el-input>
              <el-divider class="myel-divider"></el-divider>
              <span>付款方式：</span>
              <div>
                  <el-radio-group v-model="orderfrom" class="myradio">
                      <el-radio
                              v-for="(item, index) in orderFromOptions"
                              :key="index"
                              :label="item.name"
                              :value="item.type"
                      >
                      </el-radio>
                  </el-radio-group>
              </div>

            </div>
            <div>
              <div v-if="activeTicketNum&&activeTicketData.ID" class="total-price">
                <div>
                  <span>合计：</span>
                  <!-- <span style="font-size: 14px; text-decoration: line-through;">￥{{ totalMoney }}元</span> -->
                  <span style="font-size: 18px"
                  >￥{{
                      (activeTicketData.price * (activeTicketNum)).toFixed(2)
                    }}</span
                  >
                </div>
              </div>
              <el-divider class="myel-divider"></el-divider>
              <el-button v-if="activeTicketNum&&activeTicketData.ID" type="primary"
                         style="margin: 5px;float: right" @click="createOrder">确认出票
              </el-button>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>


  </div>
</template>

<script>
import DateFormat from "@/utils/DateFormat.js";

import {ticketByid, qudaoByDid, createOrderOut} from "@/network/channel.js";

export default {
  name: "detalied",
  data() {
    return {
      note:'',
      inputHang: "",
      qudaoList: [], //渠道列表
      ticket: [], //票种信息
      filterTicket: [], //票种过滤
      activeChangci: "", //选择的场次时间
      activeTicketData: {}, //选择的票种数据
      // ticketNum: 3,//当前票种的座位数
      activeTicketNum: 1, //票数量
      activeTicketNumNew: 1, //票数量
      companyId: window.sessionStorage.getItem("companyId"),
      companyName: window.sessionStorage.getItem("companyName"),
      userId: window.sessionStorage.getItem("userId"),
      id: this.$route.query.id || '0001-0001-0001-0001-0001-0001',
      type_id:'',
      qudao_id:window.sessionStorage.getItem("qudaoID"),
      showData: {},
      areaList: [],
      sessionList: [],
      areaName: "",
      person_name:'',
      person_tel:'',
      selectedDate: new Date(),
      selectedValue: "",
      selectAreaName: "",
      orderfrom: "在线预付", //支付方式
      orderFromOptions: [
        {
          name: "在线预付",
          type: 16,
        }
      ],
      selectedSession: {
        放映影片: {
          名称: "",
        },
      },
      qudao_name:''
    };
  },
  created() {
    console.log("companyName", this.companyName);
    this.qudaoByDid();
    this.getSessionList();
    this.getTicketList()
  },
  mounted() {
    console.log("mounted");
  },
  methods: {
    handleClick() {

    },
    async createOrder(){
      function formatDateTime(dateTimeStr) {
        return dateTimeStr
            .replace(/年/g, '-') // 替换“年”为“-”
            .replace(/月/g, '-') // 替换“月”为“-”
            .replace(/日/g, '')  // 去掉“日”
            .replace(/点/g, ':')  // 替换“点”为“:”
            .replace(/分/g, '');  // 去掉“分”
      }

      let params={
        userID:this.userId,
        name:'china',
        corpCode:'cy056',
        money:this.activeTicketData.price * (this.activeTicketNum)*100,
        type:'20',
        tiketTpe:this.activeTicketData.ID,
        channel:this.qudao_name,
        area:this.activeTicketData.name,
        orderform:'渠道后台',
        orderType:this.orderfrom,
        payway:this.orderform,
        number:this.activeTicketNum,
        linkPerson:{
          name:this.person_name,
          phone:this.person_tel
        },
        occDate:{
          startDate:formatDateTime(this.selectedSession.开始时间.split(' ')[0]),
          usetime:formatDateTime(`${this.selectedSession.开始时间.split(' ')[1]}-${this.selectedSession.结束时间.split(' ')[1]}`),
          endDate:this.selectedSession.结束时间
        },
        timestamp:new Date().getTime(),
        value:[{
          title:'china',
          count:this.activeTicketNum*this.activeTicketData.seat_number,
          ID:'4c2ccd61-ac5f-4b26-a792-4883ec932fd3',
          orderSeat:{
            sessionId:this.selectedSession.ID,
            areaName:this.activeTicketData.areaName
          }
        }]
      }

      if(!params.tiketTpe){
        this.$message({
          type: "warning",
          message: '请选择票种',
        });
        return
      }
      if(!params.linkPerson.phone){
        this.$message({
          type: "warning",
          message: '请输入订票人手机号码',
        });
        return
      }

      if(!params.linkPerson.name){
        this.$message({
          type: "warning",
          message: '请输入订票人姓名',
        });
        return
      }
      if(params.number<1){
        this.$message({
          type: "warning",
          message: '请输入订票数量',
        });
        return
      }
      let res=await createOrderOut(params)
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: "出票成功!",
        });
      }else {
        this.$message({
          type: "error",
          message: res.msg,
        });
      }
    },
    async qudaoByDid() {
      let res=await qudaoByDid({
        id: this.qudao_id
      })
      this.qudao_name=res.data.typeName+'-'+res.data.name
      this.type_id=res.data.typeID
    },
    // 选票种
    selectTicketType(item) {
      // this.selectedSeatList = [];
      // this.activeTicketData = {};
      this.activeTicketData = item;
      console.log(item)
      this.tipSelect = "";
    },
    // 获取票价
    async getTicketList() {
      this.ticket = [];
      let ticketres = await ticketByid({
        teamId: this.qudao_id,
        qudaoId: this.qudao_id
      });

      if (ticketres.data && ticketres.data.length > 0) {
        this.ticket = ticketres.data;
      }
      // if (ticketres_type.data && ticketres_type.data.length > 0) {
      //   this.ticket =this.ticket.concat(ticketres_type.data);
      // }
      this.ticket=this.ticket.filter(v=>{return v.name.indexOf('导游票')===-1})
      if (this.ticket.length>0){
        this.activeTicketData=this.ticket[0]
      }
      console.log(this.ticket,'-------------')
    },

    // 查找影厅信息
    getSessionList() {
      let date = new DateFormat(this.selectedDate).toString(
          "yyyy-0m-0dT08:00:00"
      );
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      this.$axios({
        url: `/manager/sessionList?currentPage=1&pageSize=10&status=4&selectedDate=${date}`,
      })
          .then((res) => {
            this.sessionList = res.data;
            //座位列表
            if (this.sessionList && this.sessionList.length > 0) {
              console.log("this.sessionList: ", this.sessionList);
              this.selectedValue = this.sessionList[0].ID;
              this.selectedSession = this.sessionList[0]
              loading.close();
            } else {
              loading.close();
              this.$message({
                type: "error",
                message: "暂无票务信息!",
              });
            }
          })
          .catch((err) => {
            loading.close();
            console.log(err);
          });
    },
    // 确认兑票
    genrateOrder(that) {
      const loading = that.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      // 影片名
      // 原价
      // 影片id
      // 数量
      // 座位id数组
      // 场次id
      // 区域名
      let seatIds = that.selectedSeatList.map((o) => o.ID);
      let goods = [
        {
          title: that.selectedSession.放映影片.名称,
          money: (that.activeTicketData.price * (that.selectedSeatList.length / (that.activeTicketData.seat_number || 1))).toFixed(2),
          id: that.selectedSession.放映影片ID,
          count: that.selectedSeatList.length,
          sceneryAreaId: that.companyId,
          sceneryAreaName: that.companyName, //景德镇记忆
          orderSeat: {
            sessionId: that.selectedSession.ID,
            areaName: this.areaName,
            seatIds,
          },
        },
      ];

      let data = {
        goods,
        address: "",
        // money: that.totalMoney,
        money: (that.activeTicketData.price * (that.selectedSeatList.length / (that.activeTicketData.seat_number || 1))).toFixed(2),
        name: that.selectedSession.放映影片.名称,
        type: 20,
        sceneryAreaId: that.companyId,
        img: that.movieInfo.封面照片,
        userid: that.userId,
        orderType: that.orderfrom, //that.orderfrom == 'PC端商户' ? '1' : '2',
        paystatus: false,
        useDate: that.selectedDate,
        orderfrom: that.orderfrom,
        fromOrderNo: that.fromOrderNo,
        qudao_type: this.activeQudaoType[0].name, //渠道类型
        qudao: this.qudao ? this.qudao : this.activeQudaoType[0].name, //渠道
        changci: this.activeChangci, //场次
        ticketID: that.activeTicketData.ID, //票id
        ticketName: that.activeTicketData.name, //票名
        是否取票: "1",
        note:this.note
      };
      console.log("data: ", data);

      that
          .$axios({
            url: `/order/genrate`,
            method: "POST",
            data,
          })
          .then((res) => {
            console.log(res);
            loading.close();
            if (res.code == 200) {
              that.$message({
                type: "success",
                message: "兑票成功!",
              });
              that.addDialogVisible = false;
              that.printTicket(
                  this.selectedSeatList,
                  res.data.订单号,
                  res.data.ID,
                  this.activeTicketData.name
              );
              that.handleClick(this.areaName);
            } else {
              that.$message.warning(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);

            loading.close();
          });
    },
    genrateERCodeURL(orderId) {
      return `https://test-pyssp.iat.wang/newminipro?type=check&ID=${orderId}&t=${new Date().getTime()}`;
    },
    // 格式化时间
    formatChangciTime(inputDateTime) {
      const regex = /(\d{4})年(\d{2})月(\d{2})日 (\d{2})点(\d{2})分/;
      const match = inputDateTime.match(regex);
      console.log("match: ", match);
      if (match) {
        const year = match[1];
        const month = match[2];
        const day = match[3];
        const hours = match[4];
        const minutes = match[5];

        // console.log(`年: ${year}, 月: ${month}, 日: ${day}, 时: ${hours}, 分: ${minutes}`);
        return `${year}/${month}/${day} ${hours}:${minutes}`;
      } else {
        console.log("未找到匹配的日期时间格式");
      }
    },
    resetSeat() {
      for (let item of this.selectedSeatList) {
        item.状态 = item.init_type || item.状态
      }
      this.selectedSeatList = []
    }
  },
  filters: {
    formatSeat(str) {
      if (str.length > 0) {
        const num = str.match(/\d+/g)[1];
        return num;
      }
      return str;
    },
  },
};
</script>

<style scoped>
hr {
  margin: 20px 5px;
}

.headcard {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: bold;
}

.setstatus {
  font-size: 14px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.setstatus span {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.seat-status {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  width: 300px;
  justify-content: space-around;
}

.seat-status > div {
  display: flex;
  align-items: center;
}

.total-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  border-top: 1px solid gainsboro;
  padding-top: 5px
}

.total-price > :nth-of-type(1) {
  width: 200px;
  text-wrap: nowrap;
  font-size: 16px;
}

#green {
  background: url("../../assets/img/selected.png") center center no-repeat;
  background-size: 100% 100%;
}

#red {
  background: url("../../assets/img/bought.png") center center no-repeat;
  background-size: 100% 100%;
}

#grey {
  background: url("../../assets/img/unselected.png") center center no-repeat;
  background-size: 100% 100%;
}

.seatarea {
  /* float: left; */
  margin: 20px;
  /* width: 2000px; */
  height: 65vh;
  overflow: auto;
  white-space: nowrap;
  padding: 20px 0 10px 0;
  position: relative;
  border: 1px solid #d1d1d1;
}

.sit {
  display: inline-block;
  height: 28px;
  width: 28px;
  min-height: 10px;
  min-width: 10px;
  margin: 2px 1px;
  background-size: cover;
//position: static;
}

.sitleft {
  display: inline-block;
  margin: 4px 4px 4px 4px;
  /* line-height: 20px; */
  position: relative;
  width: 20px;
  /* height: 20px; */
  top: 10px;
  text-align: center;
}

/* 可选座位 */
.bg-sit {
//background: url("../../assets/img/selected.png") center center no-repeat; background: url("../../assets/img/S1.png") center center no-repeat; background-size: 100% 100%;
}

/* 已锁（预售）座位 */
.bg-sit-lock {
//background: url("../../assets/img/selected.png") center center no-repeat; background: url("../../assets/img/lock.png") center center no-repeat; background-size: 100% 100%;
}

.bg-sit-selled {
//background: url("../../assets/img/selected.png") center center no-repeat; background: url("../../assets/img/S3.png") center center no-repeat; background-size: 100% 100%;
}

.bg-sit:hover {
  cursor: pointer;
}

/* 损坏座位 */
.bg-cantsit {
//background: url("../../assets/img/bought.png") center center no-repeat; background: url("../../assets/img/S2.png") center center no-repeat; background-size: 100% 100%;
}

/* 无座 */
.bg-selecting {
//background: url("../../assets/img/unselected.png") center center no-repeat; background: url("../../assets/img/selecting.png") center center no-repeat; background-size: 100% 100%;
}

.row {
  /* 清除格式并换行 */
  height: 50px;
  clear: both;
}

#tip {
  color: #8c939d;
  margin-top: 5px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20%;
  align-items: center;
}

.el-tag {
  margin-left: 10px;
  margin-top: 10px;
}

.seat-num {
  position: relative;
  top: 28px;
  color: #0e0e0e;
  font-size: 10px;
  /* border: 1px solid red; */
  text-align: center;
}

:deep .el-tabs__content {
  overflow: auto;
}

/* 票类 */
.ticket-title {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 24px; */
}

.ticket-item {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  /* border: 1px solid red; */
  justify-content: space-between;
}

.ticket-item > div {
  width: 100%;
  display: flex;
  padding: 5px;
  border-radius: 4px;
  flex-direction: column;
  margin-bottom: 1px;
  /* align-items: center; */
  justify-content: space-between;
  border: 1px solid gainsboro;
}

:deep .el-date-editor--date {
  /* width: 180px; */
}

.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.activeTicket {
  background-color: rgb(217, 236, 255);
}


.site-box {
  display: flex;
  flex-direction: column;
}

.site-box-title {
  display: flex;
  margin-left: 10%;
  user-select: none;
}

.site-box-title > div {
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid black;
}

.select-areaname {
  background-color: #48a2ff;
}

.noselectdefult {
  /* 禁止文本选中 */
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

  /* 禁止拖拽选中（针对图片或链接等） */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-drag: none; /* Safari */
}

.myradio {
  font-size: 28px;
  overflow-x: clip;
}

.myradio /deep/ .el-radio__label {
  font-size: 18px !important; /* 设置字体大小为 16 像素，可以根据需要调整 */
}

.myel-divider {
  margin: 5px 0 10px;
}
</style>
